import React, { Component } from "react";
import Slider from "react-slick";
import * as styles from "./opinionSlider.module.scss";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import onb from "../../images/ONB-grey.png";
import straub from "../../images/STRAUB-grey.png";
import { StaticImage } from "gatsby-plugin-image";

export default class OpinionSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      arrows: false,
    };
    return (
      <div className={styles.container}>
        <ul className={styles.opinionSlider}>
          <Slider {...settings}>
            <li className={styles.opinion}>
              <StaticImage
                imgClassName={styles.image}
                alt="ONB"
                src={"../../images/ONB-grey.png"}
              />
              <p className={styles.quote}>
                "Die Verwertung der Container wurde zu unserer vollsten
                Zufriedenheit durchgeführt. Wir werden im Anlassfall wieder Ihre
                Dienstleistungen in Anspruch nehmen."
              </p>
              <span className={styles.author}>Martin Schillein</span>
            </li>
            <li className={styles.opinion}>
              <StaticImage
                imgClassName={styles.image}
                alt="STRAUB"
                src={"../../images/STRAUB-grey.png"}
              />
              <p className={styles.quote}>
                "Wir haben mit Schrott24 mehrere LKW Ladungen Edelstahl
                verwertet. Der Preis lag deutlich über dem Mitbewerb. Die
                Kommunikation war so wie man es sich wünscht und auch die
                Abrechnung und Zahlung war zur vollsten Zufriedenheit."
              </p>
              <span className={styles.author}>Ronny Lamm, Straub KG</span>
            </li>
            <li className={styles.opinion}>
              <p className={styles.quote}>
                "Sehr einfache, unkomplizierte Abwicklung und fairer
                Ankaufpreis. Schon zweimal gemacht und nie Probleme gehabt.
                Einmal mit persönlicher Anlieferung und einmal als Paket
                geschickt. Kann ich nur empfehlen.""
              </p>
              <span className={styles.author}>Ein zufriedener Kunde</span>
            </li>
          </Slider>
        </ul>
      </div>
    );
  }
}
