import axios from "axios";
import {
  GET_PRODUCT_LIST,
  GET_PRODUCT_LIST_SUCCESS,
  GET_PRODUCT_LIST_ERROR,
  GET_CATEGORY_LIST,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_ERROR,
  GET_PRODUCT_DETAILS,
  GET_PRODUCT_DETAILS_SUCCESS,
  GET_PRODUCT_DETAILS_ERROR,
} from "./types";

export const getProductList = () => {
  const url =
    "https://app.scrap24.com/api/v1/products?withBuyback=true&withDescription=false&withWorstPrice=true";
  return dispatch => {
    dispatch({ type: GET_PRODUCT_LIST });
    axios
      .get(url)
      .then(data =>
        dispatch({ type: GET_PRODUCT_LIST_SUCCESS, payload: data.data })
      )
      .catch(err => {
        dispatch({ type: GET_PRODUCT_LIST_ERROR, payload: err });
      });
  };
};

export const getProductData = id => {
  const url = `https://app.scrap24.com/api/v1/products/${id}?withBuyback=true&withDescription=false`;
  return dispatch => {
    dispatch({ type: GET_PRODUCT_DETAILS });
    axios
      .get(url)
      .then(data =>
        dispatch({ type: GET_PRODUCT_DETAILS_SUCCESS, payload: data.data })
      )
      .catch(err => {
        dispatch({ type: GET_PRODUCT_DETAILS_ERROR, payload: err });
      });
  };
};

export const getCategoryList = () => {
  const url = "https://app.scrap24.com/api/v1/products/categories";
  return dispatch => {
    dispatch({ type: GET_CATEGORY_LIST });
    axios
      .get(url)
      .then(data =>
        dispatch({ type: GET_CATEGORY_SUCCESS, payload: data.data })
      )
      .catch(err => {
        dispatch({ type: GET_CATEGORY_ERROR, payload: err });
      });
  };
};
