import React, { useState } from "react";
import * as styles from "./kaufen.module.scss";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Autocomplete from "@material-ui/lab/Autocomplete";

const Kaufen = props => {
  const { productList } = props;
  const [buyingMaterial, setBuyingMaterial] = useState({});
  const [buyingQuantity, setBuyingQuantity] = useState();
  const [buyerEmail, setBuyerEmail] = useState("");
  const [buyerButtonClicked, setBuyerButtonClicked] = useState(false);

  const handleBuyerCart = () => {
    setBuyerButtonClicked(true);
    if (buyerEmail && buyingMaterial.id && buyingQuantity) {
      // TODO solve this redirection, should be also to QA or live, to at, de or ch and so on
      window.location = `https://www.schrott24.de/partner/signup?email=${buyerEmail}&productId=${buyingMaterial.id}&weight=${buyingQuantity}`;
    }
  };

  const defaultProps = {
    options: productList,
    getOptionLabel: option => option.title || option.name,
  };

  return (
    <div className={styles.hubBannerContent} id="sellerHubBannerContent">
      <h2 className={styles.altMetall}>Kaufen Sie geprüftes Altmetall</h2>
      <p className={styles.para}>
        Profitieren Sie von unserem großen Lieferantennetzwerk durch schnelle
        Verfügbarkeit und direkten Zugang zu neuen Materialquellen für unsere
        verifizierten Partner.{" "}
        <a
          className={styles.partnerWerden}
          href="https://www.schrott24.de/partner-werden/"
        >
          Sehen Sie sich die Vorteile an
        </a>
      </p>
      <form className={styles.buyerForm}>
        <div className={styles.hubBannerInputContainer}>
          <div className={styles.buyerEmail} id="hubBannerEmailBuyer">
            <TextField
              name="email"
              label="Firmen E-mail"
              margin="normal"
              id="hubBannerEmail"
              onChange={event => setBuyerEmail(event.target.value)}
              className={styles.autoComplete}
              error={buyerButtonClicked && !buyerEmail}
              helperText={
                buyerButtonClicked && !buyerEmail ? "Firmen E-mail" : ""
              }
            />
          </div>
          <div
            className={styles.containerBuyer}
            id="hubBannerMaterialContainerBuyer"
          >
            <Autocomplete
              {...defaultProps}
              freeSolo
              disableListWrap
              onChange={(event, value) => setBuyingMaterial(value)}
              clearOnBlur
              name="selectedMaterial"
              id="buyer_headerMaterialName"
              className={styles.autoComplete}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Material"
                  margin="normal"
                  helperText={
                    buyerButtonClicked && !buyingMaterial.id
                      ? "Material required"
                      : ""
                  }
                  error={buyerButtonClicked && !buyingMaterial.id}
                />
              )}
            />
          </div>
          <div
            className={styles.quantityContainerBuyer}
            id="hubBannerQuantiyContainerBuyer"
          >
            <TextField
              label="Menge (t)"
              margin="normal"
              name="fname"
              onChange={event => setBuyingQuantity(event.target.value)}
              id="hubBannerBuyerQuantityBuyer"
              helperText={
                buyerButtonClicked && !buyingQuantity ? "Menge (t)" : ""
              }
              error={buyerButtonClicked && !buyingQuantity}
              className={styles.autoComplete}
              InputProps={{
                endAdornment: <InputAdornment position="end">t</InputAdornment>,
              }}
            />
          </div>
        </div>
      </form>
      <button
        className={styles.buyerButton}
        id="hubBanner-buyer-button"
        onClick={handleBuyerCart}
      >
        WEITER
      </button>
      <div className={styles.tAndC}>
        <span className={styles.secondTC}>
          Wenn Sie fortfahren akzeptieren Sie unsere{" "}
          <a className={styles.faqDisc} href="/disclaimer/">
            Geschäftsbedingungen
          </a>
        </span>
      </div>
    </div>
  );
};
export default Kaufen;
